.side-block {
  padding: 16px;
  margin-top: 32px;
  border: 2px solid $color-gray;
  border-radius: 3px;
  background-color: $color-lightblue;
  box-shadow: 0px 0px 25px -3px $color-primary;

  h1, h2, h3, h4, h5 {
    margin-bottom: 16px;
  }
}