.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;

  &--opened {
    display: block;
  }

  &__close {
    position: fixed;
    top: 24px;
    right: 24px;
    width: 24px;
    height: 24px;
    fill: #fff;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  &__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 40px 5px rgba(0,0,0,0.5);

    & > * {
      display: block;
    }
  }

  &::before {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparentize(#000, 0.35);
  }
}