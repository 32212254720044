// Brand colors
$color-primary: #2f3542;
$color-secondary: #ff6b6b;

// Colors
$color-lightblue: #cFdcd9;
$color-lightgreen: #2bb79d;
$color-darkgray: #2f3542;
$color-gray: #747d8c;
$color-lightgray: #f1f2f6;
