.swipe-indicator {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 16px;
  border-radius: 10px;
  background-color: rgba(#000, 0.7);
  z-index: 100;

  &--hidden {
    display: none;
  }

  svg {
    width: 60px;
    height: 60px;
    fill: #fff;

    animation-name: swipe;
    animation-duration: 8s;
    animation-iteration-count: infinite;
  }

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.js-swipe-indicator-parent {
  position: relative;
}

@keyframes swipe {
  0%   {transform: translateX(0)}
  5%  {transform: translateX(-10px)}
  10%  {transform: translateX(0)}
  15%  {transform: translateX(10px)}
  20% {transform: translateX(0)}
  100% {transform: translateX(0)}
}