.certificates {
  display: flex;
  justify-content: center;

  &__link {
    width: 100px;
  }

  &__logo {
    width: 100%;
  }
}