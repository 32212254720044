@mixin button {
  display: block;
  width: max-content;
  padding: 8px 16px;
  margin-top: 16px;
  outline: none;
  text-align: center;
  text-decoration: none;
}

.button {
  &__default {
    @include button;
    color: #fff;
    background-color: $color-secondary;
    border: 2px solid $color-secondary;
    border-radius: 3px;
    transition: background-color .15s linear;

    &:hover,
    &:focus {
      background-color: #fff;
      color: #e83c3c;
      transition: background-color .15s linear;
    }
  }
}