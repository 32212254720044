.pricing {
  margin-top: 48px;
  padding: 24px 16px;
  border-radius: 3px;
  background-color: $color-lightblue;

  &__list {
    margin-bottom: 0;

    dt,
    dd {
      width: 49%;
      display: inline-block;
      margin: 6px 0 0;
    }
  }

  p {
    font-style: italic;
    margin: 0;

    &:first-of-type {
      margin-top: 16px;
    }
  }
}