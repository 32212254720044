.download {
  margin-top: 32px;
  
  &__list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 16px;
  }

  &__list-item {
    padding: 0;
    margin: 8px 0;


  }

  &__link {
    display: block;
    position: relative;
    padding: 16px 8px;
    color: #777;
    background-color: #ddd;
    border-left: 3px solid #777;
    text-decoration: none;
    outline: none;
    transition: border 0.2s linear;

    &:hover,
    &:focus {
      border-color: #000;
      .download__icon {
        opacity: 1;
      }
    }
  }

  &__icon {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.3;
    transition: opacity 0.2s linear;
  }
}