.schedule {
  margin-top: 32px;
  overflow: auto;


  &__table {
    margin-top: 16px;
    width: 100%;

    thead {
      background-color: $color-lightgray;
      font-weight: $font-weight-bold;
    }

    tr {
      td {
        border: 3px solid transparent;
        white-space: nowrap;

        a {
          color: $color-gray;
          text-decoration: none;

          &:hover {
            color: $color-primary;
            text-decoration: underline;
          }
        }
      }

      td:first-child {
        border-left: 0;
      }
    }
  }

  @include media-breakpoint-only(xs) {
    padding-left: 0;
  }
}