.rich-text {
  img.rt-inline {
    width: 250px;
    height: auto;
    padding: 16px 0 16px 16px;

    &--right {
      float: right;
    }
  }
}