.gallery {
  margin-top: 48px;
  overflow: hidden;

  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 16px;
    overflow: auto;

    padding-bottom: 24px;
    margin-bottom: -24px;

    @each $bp, $container-width in $container-widths {
      @include media-breakpoint-only($bp) {
        @if $bp == xs {
          padding-left: 0;
        } @else if $bp == sm {
          padding-left: calc(50% - 0.5*#{$container-width});
        } @else {
          padding: 0 calc(50% - 0.5*#{$container-width}) 24px;
        }

      }
    }

    @include media-breakpoint-up(md) {
      flex-wrap: wrap;
    }
  }

  &__item {
    margin: 16px 0;
  }

  &__img-wrapper {
    position: relative;
    height: 0;
    padding-top: 100%;
    overflow: hidden;
    outline: none;

    img {
      position: absolute;
      top: 0;
      max-width: 100%;
      transition: transform 0.2s linear;
    }

    &:hover,
    &:focus {
      img {
        transform: scale(1.03);
      }
    }
  }
}