/* Box sizing, responsive, and more */

@at-root {
  @-ms-viewport { width: device-width; }
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container .row,
.container-fluid .row {
  justify-content: center;
}

@import
"../../node_modules/bootstrap/scss/variables",
"../../node_modules/bootstrap/scss/normalize",
"../../node_modules/bootstrap/scss/mixins",
"../../node_modules/bootstrap/scss/utilities",
"../../node_modules/bootstrap/scss/grid";

/* Override grid */
@include media-breakpoint-down(xs) {
  .row {
    margin-left: 0;
    margin-right: 0;
  }
}
