.offer {
  margin-top: 32px;

  &__image {
    width: 100%;
    margin-top: 16px;
    border-radius: 3px;

    @include media-breakpoint-up(md) {
      padding-right: 16px;
    }
  }

  &__intro {
    @include media-breakpoint-up(md) {
      padding-right: 16px;
    }
  }

  @include media-breakpoint-up(md) {
    padding-right: 16px;
    margin: 48px 0 0;
  }
}