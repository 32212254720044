@import "./bootstrap";
@import "../json/global-variables.json";
@import url('https://fonts.googleapis.com/css?family=Tangerine:700');

// Variables
@import "./variables/colors";

// Cool css
.reset-list{list-style:none; margin:0; padding:0;}

h1, h2, h3, h4, h5 {
  color: $color-darkgray;
  margin: 0;
}

body {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  main {
    flex-grow: 1;
    max-width: 100vw;
    padding: 32px 0;

    @include media-breakpoint-up(md) {
      padding: 64px 0 32px;
    }

    p {
      line-height: 1.3;
      margin-bottom: 0;
    }
  }
}

.container {
  @include media-breakpoint-only(xs) {
    padding: 0;
  }
}

.body--locked {
  overflow: hidden;
  width: 100%;
}

// Components
@import "components/button",
        "components/certificates",
        "components/contact-box",
        "components/download",
        "components/gallery",
        "components/modal",
        "components/navigation-menu",
        "components/rich-text",
        "components/offer",
        "components/page-footer",
        "components/pricing",
        "components/schedule",
        "components/side-block",
        "components/swipe-indicator",
        "components/top-bar";
