.top-bar {
  background-color: $color-lightgreen;
  box-shadow: 0 2px 5px $color-primary;
}

.logo {
  display: block;
  color: $color-primary;
  font-family: 'Tangerine';
  font-size: 32px;
  text-decoration: none;
  margin: 12px 0;

  @include media-breakpoint-up(md) {
    font-size: 40px;
    margin: 18px 0;
  }
}