.contact-box {

    &__excerpt {
    margin-top: 16px;

    li {
      margin-bottom: 4px;
    }

    a {
      display: inline-block;
      position: relative;
      color: inherit;
      text-decoration: none;
      font-weight: 700;
      outline: none;

      &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: -2px;
        width: 100%;
        height: 2px;
        background-color: transparentize($color-gray, 0.7);
      }

      &:hover,
      &:focus {
        &::before {
          background-color: $color-gray;
        }
      }
    }
  }

  &__photo {
    width: 100%;
  }

  &__photo-wrapper {
    height: 100px;
    width: 100px;
    position: absolute;
    transform: translate(-6%, 0%) rotate(5deg);
    top: 0;
    right: 0;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid $color-gray;
    box-shadow: 0px 0px 25px -5px $color-primary;

    @include media-breakpoint-up(md) {
      transform: translate(25%, -25%) rotate(5deg);
    }
  }

  .button__default {
    margin-top: 24px;
    margin-left: auto;
  }

  @include media-breakpoint-up(md) {
    margin-top: 0;
  }
}

.side-bar {
  @include media-breakpoint-only(xs) {
    margin-top: 16px;
  }
}