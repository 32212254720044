.navigation-menu {
  display: flex;
  justify-content: flex-start;

  &__list {
    display: flex;
    flex-direction: column;
    padding: 8px 0 0;

    @include media-breakpoint-up(md) {
      height: 100%;
      align-items: center;
      flex-direction: row;
      padding: 0;
    }
  }

  &__list-item {
    display: flex !important;
    align-items: center;

    padding: 10px 0;
    margin-bottom: 8px;
    transition: background-color 0.2s ease-out;

    a {
      position: relative;
      color: $color-primary;
      font-size: 18px;
      text-decoration: none;
    }

    @include media-breakpoint-up(md) {
      display: inline-block;
      height: 100%;
      padding: 0 10px;
      margin-bottom: 0;

      &:hover {
        transition: background-color 0.15s ease-out;
        background-color: $color-primary;

        a {
          color: $color-lightgreen;
        }
      }

      &--cta {
        &:hover {
          background-color: $color-secondary;

          a {
            color: #fff;
          }
        }
      }
    }
  }

  &__hamburger {
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &__close {
    height: 22px;
    width: 22px;
    position: absolute;
    top: 20px;
    right: 16px;
    cursor: pointer;
  }

  &__hamburger,
  &__close {
    padding: 2px;
    fill: $color-primary;
    transition: background-color 0.15s ease-out;

    &:hover,
    &:focus {
      background-color: transparentize($color-primary, 0.7);
      border-radius: 3px;
      transition: background-color 0.15s ease-out;
      outline: none;
    }
  }

  @include media-breakpoint-down(sm) {
    position: fixed;
    left: 0;
    top: -100vh;
    height: 100vh;
    width: 100vw;
    background-color: $color-lightgreen;
    z-index: 100;
    transition: top 0.4s ease-out;

    > * {
      opacity: 0;
      transition: opacity 0.4s ease-out 0.3s;
    }

    &--active {
      transition: top 0.4s ease-out;
      top: 0;

      > * {
        opacity: 1;
        transition: opacity 0.4s ease-out 0.3s;
      }
    }
  }

  @include media-breakpoint-up(md) {
    justify-content: flex-end;
    align-items: center;
  }
}
